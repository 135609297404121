import { Box, Divider, Flex, Heading, HStack, Text } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Layout, Seo } from "../../components";
import "../../styles/screen.scss";

const seoBundle = {
  title:
    "Pitfalls to avoid when requesting quotes for a steel building project",
  subtitle: "Navigating the Quoting Process for Your Steel Building Project",
  description:
    "Embarking on a new steel building project is an exciting venture, whether it's for a spacious garage, a new workshop, or a storage facility. However, the path to your perfect steel structure is laden with decisions, and one crucial step stands out: getting an accurate quote.",
  slug: "/articles/pitfalls-to-avoid-when-pricing-a-steel-building",
};

const Blog2 = ({ location }) => {
  return (
    <Layout title={"Home"} location={location}>
      <Seo seoBundle={seoBundle} />
      <article>
        <Flex
          maxW='1450px'
          h='100%'
          // header takes up 40/50 pixels
          pt={["150px", null, "150px"]}
          px={["20px", null, "100px"]}
          pb={["60px", null, "100px"]}
          align='center'
          justify='center'
          flexDirection='column'
        >
          <Heading
            as='h1'
            color='dkGrey'
            mb={["20px", null, null, "10px"]}
            mt='0px'
            fontWeight={800}
            fontSize={["3em", "4em"]}
            fontFamily='heading'
            lineHeight={["100%", null, null, "120%"]}
            textAlign='center'
          >
            Navigating the Quoting Process for Your Steel Building Project
          </Heading>
          <Heading
            as='h2'
            color='dkGrey'
            mb={["20px", null, null, "10px"]}
            mt='0px'
            fontWeight={800}
            fontSize={["1.5em", "2em"]}
            fontFamily='body'
            lineHeight={["120%"]}
            textAlign='center'
          >
            Pitfalls to Avoid
          </Heading>
          <Flex flexDir='row'>
            <Text my='30px' fontWeight={600} textAlign='center'>
              “The path to your perfect steel structure is laden with decisions,
              and one crucial step stands out: getting an accurate quote.”
            </Text>
          </Flex>
          <HStack display='flex' align='center'>
            <Box
              w='100%'
              display='flex'
              h={["60px"]}
              w={["60px"]}
              justifyContent='center'
              mr='10px'
            >
              <StaticImage
                src='../images/jake.jpg'
                alt='Jake Fillmore, owner and CEO'
                placeholder='blurred'
                layout='constrained'
                style={{ borderRadius: "50%" }}
              />
            </Box>
            <Flex flexDir='column'>
              <Text fontFamily='heading' mb='0' fontWeight={600}>
                Jake Fillmore
              </Text>
              <Text as='span' mt='0' pt='0' fontSize='12px'>
                CEO, Owner & Operator <br />
                <Text fontFamily='heading' fontWeight={700}>
                  JMF Steel Construction
                </Text>
              </Text>
            </Flex>
          </HStack>
          <Flex
            w='100%'
            my={["30px"]}
            h={["200px", null, "200px", "400px", "600px"]}
            // w={["250px", "350px", "150px", "250px", "350px"]}
            justifyContent='center'
          >
            <StaticImage
              src='../../images/background.png'
              alt='roof top garden in large city'
              placeholder='blurred'
              // height={200}
              // width={200}
              // height={200}
            />
          </Flex>
          <Text
            mt='20px'
            mb='7'
            className='cap'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
          >
            Embarking on a new steel building project is an exciting venture,
            whether it's for a spacious garage, a new workshop, or a storage
            facility. However, the path to your perfect steel structure is laden
            with decisions, and one crucial step stands out: getting an accurate
            quote. Many enthusiastic future building owners trip up during this
            critical phase, so let's explore the common mistakes you should
            sidestep to ensure your project doesn't hit any snags.
          </Text>
          <Heading
            as='h2'
            mt='40px'
            color='dkGrey'
            mb={["20px", null, null, "10px"]}
            fontWeight={800}
            fontSize={["1.5em", "2em"]}
            fontFamily='body'
            lineHeight={["120%"]}
            textAlign='center'
          >
            Rushing for Price Tags Without Solid Specs
          </Heading>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            A classic misstep in the quoting process is gunning for a price
            without first clarifying the specifics of your project. It's like
            asking how much a bag of groceries costs without specifying what's
            inside. Finalizing the exact location, dimensions, and design of
            your building is paramount. Remember, size matters, especially since
            most Pre-Engineered Metal Buildings (PEMBs) cap out at a single
            story. You might need more elbow room than you first thought,
            necessitating a broader or longer structure compared to conventional
            multi-story buildings.
          </Text>
          <Heading
            as='h2'
            mt='40px'
            color='dkGrey'
            mb={["20px", null, null, "10px"]}
            fontWeight={800}
            fontSize={["1.5em", "2em"]}
            fontFamily='body'
            lineHeight={["120%"]}
            textAlign='center'
          >
            Skimping on the Planning Phase
          </Heading>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            An all-too-common blunder is underestimating the planning stage.
            This isn't the time for guesstimates; you need a comprehensive plan.
            Work closely with your supplier or contractor to hammer out every
            detail, from customizations to door installations. It's akin to
            blueprinting your project, ensuring every nut, bolt, and steel beam
            fits your vision to a T.
          </Text>
          <Heading
            as='h2'
            mt='40px'
            color='dkGrey'
            mb={["20px", null, null, "10px"]}
            fontWeight={800}
            fontSize={["1.5em", "2em"]}
            fontFamily='body'
            lineHeight={["120%"]}
            textAlign='center'
          >
            Ignoring the Devil in the Details
          </Heading>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            When soliciting quotes, the more information, the merrier. Ensure
            you're handing over specifics like the ZIP/postal code of your job
            site (local codes wait for no build), your preferred design and roof
            style, door and window requirements, and insulation needs. A
            ballpark figure based on guesstimates won’t cut it. Be prepared for
            the final amount to shift, especially considering that delivery and
            equipment fees love to play hide-and-seek in initial estimates.
          </Text>
          <Heading
            as='h2'
            mt='40px'
            color='dkGrey'
            mb={["20px", null, null, "10px"]}
            fontWeight={800}
            fontSize={["1.5em", "2em"]}
            fontFamily='body'
            lineHeight={["120%"]}
            textAlign='center'
          >
            Falling for the Apples-to-Oranges Comparison
          </Heading>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            In the digital era, a quick online search will bombard you with an
            array of prices. But caution! Not all prefab metal buildings are
            created equal. That bargain deal might be for a building with hollow
            tubular framing, the lesser cousin of the robust, solid-steel I-beam
            frames. The former doesn't buddy up with longevity or high wind/snow
            loads. If the price seems too good to be true, it probably is.
            Remember, you’re investing in a structure, not just buying a
            product.
          </Text>
          <Heading
            as='h2'
            mt='40px'
            color='dkGrey'
            mb={["20px", null, null, "10px"]}
            fontWeight={800}
            fontSize={["1.5em", "2em"]}
            fontFamily='body'
            lineHeight={["120%"]}
            textAlign='center'
          >
            Skipping the Homework on Manufacturers
          </Heading>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            Lastly, don't be that person who skips the reviews. Sure, we at
            BuildingsGuide have your back, serving up only top-tier, thoroughly
            vetted manufacturers. But for that extra pillow of confidence, dive
            into customer reviews and testimonials. Double-checking that your
            chosen supplier is the crème de la crème ensures your project rests
            in the best hands.
          </Text>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            Sidestepping these pitfalls isn't just about saving money; it's
            about safeguarding your investment. By ensuring a thorough approach
            to the quoting process—from detailed planning to diligent
            research—you're not just buying a steel building; you're laying the
            foundation for a project built on confidence and peace of mind. So,
            equip yourself with these insights, and you're ready to nail the
            perfect deal for your steel building endeavor.
          </Text>

          <Divider maxW='600px' my='40px' orientation='horizontal' />
          <Heading as='h3' textAlign='center'>
            JMF Construction
          </Heading>
          <Heading as='h4' fontFamily='body' fontSize='16px'>
            A Boise, Idaho Company
          </Heading>
          <Text
            mt='20px'
            mb='7'
            textAlign='center'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
          >
            We’ve done projects all over the Treasure Valley and beyond for
            businesses such as Simplot Western Stockmen’s, CSDI, Champion
            Produce in Parma, and Farm Equipment USA in Fruitland and Jerome.
            Have a small project? You’ve come to the right place. We build
            smaller projects regularly, not just the large scale ones.
          </Text>
          <Text
            mt='20px'
            mb='7'
            textAlign='center'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
          >
            Here at JMF, we make it our business to get to know you and your
            unique project. Call us today!
          </Text>
          <Text
            mb='40px'
            mt='20px'
            fontSize={24}
            textAlign='center'
            maxW='800px'
            lineHeight='120%'
            fontWeight='bold'
            color='dkGrey'
          >
            Call us at <a href='tel:2088801062'>(208) 880-1062</a>
          </Text>
        </Flex>
      </article>
    </Layout>
  );
};

export default Blog2;
